import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
`;

const Grid = styled.div`
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
  gap: 15px;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #00796b #f0f0f0;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #f0f0f0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #00796b;
    border-radius: 10px;
    border: 2px solid #f0f0f0;
  }
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.disabled ? '#cccccc' : '#005b4f')};
  font-size: 1.2em;
  font-weight: bold;
  height: 50px;
  border: 1px solid ${(props) => (props.selected ? '#005b4f' : '#00796b')};
  border-radius: 10px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: transform 0.2s, background-color 0.2s;
  background-color: ${(props) => (props.disabled ? '#f0f0f0' : 'white')};

  &:hover {
    transform: ${(props) => (props.disabled ? 'none' : 'scale(1.05)')};
  }
`;

const AddButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #00796b;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #005b4f;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const NumberOfPeopleSelector = ({
  numPeople,
  setNumPeople,
  from_restaurant,
  limit_people_reservation,
}) => {
  const [array, setArray] = useState(16);

  const handleAddPeople = () => {
    if (from_restaurant || array < limit_people_reservation) {
      setArray((prev) => prev + 16);
    }
  };

  const handleBoxClick = (number) => {
    if (from_restaurant || number <= limit_people_reservation) {
      setNumPeople(number);
    }
  };

  return (
    <Container>
      <span
        style={{
          borderBottom: '1px solid black',
          paddingBottom: '10px',
          color: 'black',
        }}
      >
        <FontAwesomeIcon icon={faUser} /> Número de pessoas
      </span>
      <Grid>
        {Array.from({ length: array }, (_, index) => {
          const number = index + 1;
          const disabled = !from_restaurant && number > limit_people_reservation;
          return (
            <Box
              key={index}
              selected={numPeople === number}
              onClick={() => handleBoxClick(number)}
              disabled={disabled}
            >
              {number}
            </Box>
          );
        })}
      </Grid>
      <AddButton
        onClick={handleAddPeople}
        disabled={!from_restaurant && array >= limit_people_reservation}
      >
        + Pessoas
      </AddButton>
    </Container>
  );
};

export default NumberOfPeopleSelector;
