import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import { faPaperPlane, faComments } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InputContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border: 1px solid #ced4da; /* This color matches Bootstrap's form control border color */
    border-radius: 0.25rem; /* This value matches Bootstrap's form control border radius */
`;

const StyledInput = styled.input`
    flex-grow: 1;
    border: none;
    padding: 0.375rem 0.75rem; /* These values match Bootstrap's form control padding */
    &:focus {
        outline: none;
    }
`;

const SendButton = styled.button`
    border: none;
    background: none;
    cursor: pointer;
    padding: 0 15px;
    &:focus {
        outline: none;
    }
`;

const ChatButton = styled.button`
    position:relative;
    border: 1px solid #63E6BE;
    border-radius:10px;
    background: none;
    cursor: pointer;
    padding: 0 15px;
    height:40px;
`;

const MessageBubble = styled.div`
    display: flex;
    flex-direction: column; // Ensures that the content is laid out in a column, with the info above the message
    max-width: 60%;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 20px;
    color: #fff;
    word-break: break-word; // This will break long words to prevent overflow
    overflow-wrap: break-word; // Alternative to word-break, with similar behavior
    &.user-message {
        background-color: #63E6BE; /* Light green for user messages */
        margin-left: auto;
        border-bottom-right-radius: 0;
    }

    &.other-message {
        background-color: #6C757D; /* Gray for other messages */
        margin-right: auto;
        border-bottom-left-radius: 0;
    }

    .message-info {
        font-size: 0.8rem;
        margin-bottom: 5px;
        text-align: right;
    }
`;

const ChatHistoryContainer = styled.div`
    height: 400px;
    overflow: auto;
    margin-bottom: 20px;
    padding:10px;
    
  /* Modern Scrollbar Style */
  &::-webkit-scrollbar {
    width: 10px; /* Adjust width for vertical scrollbar */
    height: 10px; /* Adjust height for horizontal scrollbar */
  }

  &::-webkit-scrollbar-track {
    background-color: #f4f4f4; /* Color of the track of scrollbar */
    border-radius: 10px; /* Adjust border-radius for the track */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bdbdbd; /* Color of the thumb of scrollbar */
    border-radius: 10px; /* Adjust border-radius for the thumb */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a8a8a8; /* Color of the thumb of scrollbar when hovered */
  }

  /* For Internet Explorer */
  -ms-overflow-style: auto;
`;

const TimestampCenter = styled.div`
    text-align: center;
    font-size: 0.8rem;
    margin-bottom: 5px;
    color: #6C757D; /* Gray to match other-message color */
`;

const SenderName = styled.div`
    margin-bottom: 5px;
    text-align: ${props => props.isUserMessage ? 'right' : 'left'};
    color: #6C757D; // Gray color for the sender's name
    font-size: 0.9rem;
`;

const NotificationIndicator = styled.div`
  position:absolute;
  top:3px;
  right:3px;
  background-color: ${({ isActive }) => (isActive ? '#4B3335' : '#4B3335')};
  border: ${({ isActive }) => (isActive ? 'none' : '1px solid #4B3335')};
  width: 10px; /* Notification size */
  height: 10px;
  border-radius: 50%;
  animation: ${({ isActive }) => (isActive ? 'pulse 1.5s infinite' : 'none')};

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.3);
      opacity: 0.7;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

const StyledTextarea = styled.textarea`
    flex-grow: 1;
    border: none;
    padding: 0.375rem 0.75rem; /* These values match Bootstrap's form control padding */
    resize: none; /* Prevents the user from resizing the textarea */
    &:focus {
        outline: none;
    }
  /* Modern Scrollbar Style */
  &::-webkit-scrollbar {
    width: 10px; /* Adjust width for vertical scrollbar */
    height: 10px; /* Adjust height for horizontal scrollbar */
  }

  &::-webkit-scrollbar-track {
    background-color: #f4f4f4; /* Color of the track of scrollbar */
    border-radius: 10px; /* Adjust border-radius for the track */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bdbdbd; /* Color of the thumb of scrollbar */
    border-radius: 10px; /* Adjust border-radius for the thumb */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a8a8a8; /* Color of the thumb of scrollbar when hovered */
  }

  /* For Internet Explorer */
  -ms-overflow-style: auto;
`;

function ReservationChat({ reservation, isClient=false, setChatModalTrueOrFalse=false, openModal=false, hideButton=false}) {
    const [showModal, setShowModal] = useState(false);
    const [chatHistory, setChatHistory] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const socket = useRef(null);
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    const chatEndRef = useRef(null);

    useEffect(() => {
        if (openModal === true) {
            setShowModal(openModal);
        }
       
    },[openModal]);


    useEffect(() => {
        if (showModal) {
            let sender = userInfo ? userInfo.name : reservation.full_name;
        const websocketBaseURL = process.env.REACT_APP_WEBSOCKET_URL;
        socket.current = new WebSocket(`${websocketBaseURL}chat/init/${reservation.token}/${sender}/${isClient ? 'client': 'restaurant'}/`);
    
        socket.current.onmessage = (event) => {
            const data = JSON.parse(event.data);
        
            // Check for chat history type message
            if (data.type === 'chat_history') {
                // Sort the messages by timestamp in ascending order
                const sortedMessages = data.messages.sort((a, b) => {
                    const dateA = new Date(a.timestamp);
                    const dateB = new Date(b.timestamp);
                    return dateA - dateB;
                });
                setChatHistory(sortedMessages);
            } else {
                // For normal chat messages, append to the current chat history
                // and ensure the chatHistory is sorted after appending the new message
                setChatHistory((prevHistory) => {
                    const updatedHistory = [...prevHistory, data];
                    return updatedHistory.sort((a, b) => {
                        const dateA = new Date(a.timestamp);
                        const dateB = new Date(b.timestamp);
                        return dateA - dateB;
                    });
                });
            }
            console.log('data.messages: ', data.messages);
        };
        
        socket.current.onopen = () => {
            console.log('WebSocket Connected');
        };
    
        socket.current.onclose = () => {
            console.log('WebSocket Disconnected');
        };
    
        return () => socket.current?.close();

        }
        
    }, [userInfo, showModal]);

    const sendMessage = () => {
        if (socket.current && newMessage.trim()) {
            socket.current.send(JSON.stringify({ message: newMessage, 
                                                sender: userInfo ? userInfo.name : reservation.full_name, 
                                                client_or_restaurant: isClient ? 'client' : 'restaurant' }));
        }
        setNewMessage('');
        console.log('isClient',isClient, isClient ? 'client' : 'restaurant')
    };

    const isUserMessage = (senderName) => {
        if (userInfo && senderName === userInfo.name) {
            return true;
        } else if (!userInfo && senderName === reservation.full_name) {
            return true;
        }
        return false;
    };

    const closeModal = () => {
        if (setChatModalTrueOrFalse) {
            setChatModalTrueOrFalse(false);
        }
        setShowModal(false);
    };

    useEffect(() => {
        chatEndRef.current?.scrollIntoView();
        console.log('reservation', reservation && reservation.chat_notification_restaurant)
        console.log('userInfo: ', userInfo && userInfo.name)
    }, [chatHistory, reservation, showModal]);

    return (
        <>
        {!hideButton && 
            <>
                <ChatButton variant="primary" onClick={() => setShowModal(true)}>
                    {isClient && 'Chat com o restaurante '}
                    <FontAwesomeIcon size='xl' icon={faComments} style={{ color: "#63E6BE" }} />
                        <>
                        {reservation.chat_has_messages && (
                            <NotificationIndicator
                                isActive={
                                    (reservation.chat_notification_restaurant &&
                                    Array.isArray(reservation.chat_notification_restaurant) &&
                                    reservation.chat_notification_restaurant.includes(userInfo && userInfo.name)) || 
                                    reservation.chat_notification_client
                                }
                            />
                        )}
                        </>
                </ChatButton>
            </>
        }
            

            <Modal show={showModal} onHide={() => closeModal()} size="lg">
                <Modal.Header closeButton>
                    <h6>{reservation.full_name}, dia:{reservation.date} {reservation.time}, pessoas:{reservation.number_of_people}</h6>
                </Modal.Header>
                <Modal.Body>
                <ChatHistoryContainer>
                {chatHistory.map((msg, index) => (
                    <React.Fragment key={index}>
                        <SenderName isUserMessage={isUserMessage(msg.sender)}>
                            <span className="sender">{msg.sender}</span>
                        </SenderName>
                        <TimestampCenter>
                            <span>{msg.timestamp}</span>
                        </TimestampCenter>
                        <MessageBubble className={isUserMessage(msg.sender) ? 'user-message' : 'other-message'}>
                            <div className="message-content">
                                {msg.message.split('\n').map((line, index) => (
                                    <React.Fragment key={index}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </div>
                        </MessageBubble>
                    </React.Fragment>
                ))}
                    <div ref={chatEndRef} />
                </ChatHistoryContainer>
                    <InputContainer>
                    <StyledTextarea
                            placeholder="Escreva uma mensagem..."
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                        />

                        <SendButton onClick={sendMessage}>
                            <FontAwesomeIcon size='lg' icon={faPaperPlane} style={{ color: "#63E6BE" }} />
                        </SendButton>
                    </InputContainer>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ReservationChat;
