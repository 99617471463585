import React, { useEffect } from 'react';
import { update } from 'react-spring';
import styled from 'styled-components';

const CustomSelectOption = styled.div`
  height :auto;
  display: inline-flex;
  align-items: center;
  position: relative;
  padding: 5px;
  font-size: 1rem;
  border: 1px solid ${({ borderColor }) => borderColor || 'black'};
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: ${({ isF, fromRestaurant }) =>
    isF && !fromRestaurant ? 'none' : 'pointer'};
  pointer-events: ${({ isF, fromRestaurant }) =>
    isF && !fromRestaurant ? 'none' : 'auto'};
  background-color: ${({ isSelected, isF, fromRestaurant }) =>
    isF && !fromRestaurant ? '#ed5d1a' : isSelected ? '#28d493' : 'transparent'};
  color: ${({ isSelected, isF, fromRestaurant }) =>
    isF && !fromRestaurant ? 'white' : isSelected ? 'white' : 'black'};
  transition: all 0.2s ease-in-out;
  margin-right: 10px;

  &:hover {
    border-color: #88f1d3;
  }
`;

const CustomSelectText = styled.span`
  margin-right: 5px;

  span {
    white-space: nowrap; /* Prevent line breaks for "Lotado" */
  }
`;

const WarningMessage = styled.span`
  color: red;
  position:absolute;
  top: 31px;
  font-size: 13px;
  left: 0;
`;


const CustomSelectCheckWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid black;
  border-radius: 50%;
  background-color: white;
  margin-left: 5px;
  position: relative;
`;

const CustomSelectCheck = styled.span`
  position: absolute;
  z-index: 1;
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};
  color: black;
`;


function CustomSelect({
  items,
  onSelectChange,
  selectedItem,
  getBorderColor,
  update,
  setUpdate = false,
  from_restaurant = false,
}) {
  const handleItemClick = (item) => {
    onSelectChange(item);
    if (setUpdate) {
      setUpdate(update + 1);
    }
  };

  return (
    <div>
      {items && (
        <>
          {items.map((item, index) => (
            <CustomSelectOption
              key={item}
              id={`custom-select-option-${index}`}
              isSelected={selectedItem === item}
              onClick={() => handleItemClick(item)}
              borderColor={getBorderColor ? getBorderColor(item) : undefined}
              isF={item.endsWith('/f')}
              fromRestaurant={from_restaurant}
            >
              <CustomSelectText>
                {item === 'Lunch' || item === 'Lunch/f'
                  ? 'Almoço'
                  : item === 'Dinner' || item === 'Dinner/f'
                  ? 'Jantar'
                  : item === 'Breakfast' || item === 'Breakfast/f'
                  ? 'Pequeno Almoço'
                  : item === 'reservation'
                  ? 'Reserva'
                  : item === 'waiting_list'
                  ? 'Lista de espera'
                  : item.endsWith('/f')
                  ? item.slice(0, -2)
                  : item}
                {item.endsWith('/f') && !from_restaurant && (
                  <span style={{ color: 'white' }}> | Lotado*</span>
                )}
              </CustomSelectText>
              <CustomSelectCheckWrapper>
                <CustomSelectCheck isSelected={selectedItem === item}>
                  &#10003;
                </CustomSelectCheck>
              </CustomSelectCheckWrapper>
            </CustomSelectOption>
          ))}
        </>
      )}
    </div>
  );
}

export default CustomSelect;