import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { css, keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faExclamationTriangle, faPlus, faCalendar, 
         faFilter, faSun, faMap, faCircleDot, 
         faStopwatch, faChair, faUsers, faCircleXmark, faCircleCheck,
         faFilePen, faRectangleXmark, faEnvelope, 
         faWheelchairMove, faBaby, faReply,
         faSearch, faSquareCheck, faEye, faPhone, faXmark, faGear,
         faPhoneSlash } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { 
  fetchAllReservations, postReservationAccept  } from '../actions/restaurantActions';
  import { fetchCancelReservation, 
    fetchReservationShift, 
    fetchReservationBlockedWeekdays,
    checkCancelAction } from '../actions/reservationsActions';
import Messages from '../components/Messages';
import Loader from '../components/Loader';
import ReservationChat from '../components/ReservationChat';
import ReservationScreen from '../screens/ReservationScreen';
import CompareChanges from './CompareChanges';
import ReservationStatusBox from './ReservationStatusBox'
import {cancelledStatuses} from './ReservationStatus'

const slideIn = keyframes`
  from {
    transform: translateX(100%); /* Start from the right */
  }
  to {
    transform: translateX(0); /* Slide to original position */
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0); /* Start from original position */
  }
  to {
    transform: translateX(100%); /* Slide to the right */
  }
`;


const BarContainer = styled.div`
  width: 100vw; /* Viewport width */
  height: 100vh; /* Viewport height */
  position: fixed;
  top: 0; /* Position from the top */
  left: 0; /* Position from the left */
  z-index: 1000; /* High z-index to stay on top */
  
  /* Conditional animations */
  animation: ${props => (props.clicked ? slideIn : slideOut)} 0.5s forwards;
`;



const InsideContainerBox = styled.div`
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  margin:20px;
  width: 100%;
  height:95%;
  border-radius:10px;
  background-color:white;
  overflow:hidden;
  display:flex;
`;

const InsideContainer = styled.div`
  position:relative;
  padding:30px;
  width: 85%;
  background-color:white;
  height:100%;
  overflow:auto;
  /* This styles the scrollbar track (the background where the thumb moves) */
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent; /* or use a light shade of gray */
  }

  /* This styles the scrollbar thumb (the draggable element) */
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #cccccc; /* choose a color that suits your design */
    &:hover {
      background-color: #bbbbbb; /* slightly darker shade on hover */
    }
  }

  /* This styles the scrollbar itself */
  &::-webkit-scrollbar {
    width: 12px;
    background-color: transparent; /* makes the background of the scrollbar transparent */
  }
`;
const orbit = (start, end) => keyframes`
  from {
    transform: rotate(${start}deg);
  }
  to {
    transform: rotate(${end}deg);
  }
`;

const CircleContainer = styled.div`
  width: 100px; 
  height: 100px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  position: fixed;
  bottom: 50px;
  right: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  cursor: pointer;
  padding: 2px;
  z-index:1001;
`;

const BellIcon = styled.svg`
  fill: green; 
  /* Other styling for your bell icon */
  font-size:20px;
`;

const NotificationCircle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  bottom: 66px; /* Half the size of the small circle */
  right: 66px; /* Half the size of the small circle */

  /* This wrapper helps keep the number inside the circle straight while the circle itself rotates. */
  & > div {
    display: flex;
    justify-content: center;
    align-items: center; /* Counter the initial 45deg rotation of the parent */
  }

  ${({ animationDegrees, animate }) =>
    animate &&
    css`
      transform-origin: 50px 50px; /* The center of the main circle */
      animation: ${orbit(animationDegrees.start, animationDegrees.end)} 0.5s forwards;
    `}
`;

const YellowCircle = styled(NotificationCircle)`
  color: white; 
  background: #EE754C;
`;

const RedCircle = styled(NotificationCircle)`
  color: white; 
  background: red;
`;

const tremble = keyframes`
0% { transform: rotate(0deg); }
25% { transform: rotate(-6deg); }
75% { transform: rotate(6deg); }
100% { transform: rotate(0deg); }
`;

const AnimatedIcon = styled(FontAwesomeIcon)`
  color: #5fddb3; 
  font-size: 60px;
  ${({ animate }) =>
    animate &&
    css`
      animation: ${tremble} 0.2s ease-in-out 3; /* Adjust time and iteration count as needed */
    `}
`;

const StyledIcon = styled(FontAwesomeIcon)`
  margin: 0; 
  padding: 0;
  font-size:28px;
`;

const ReservationCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  border: 1px solid #cbd1cf;
  border-radius:10px;
  padding: 0;
  position: relative;
  overflow:hidden;
  width:100%;
  height:auto;
  margin-bottom:4px;
`;

const ReservationTopBot = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const ReservationTop = styled.div`
  display: flex;
  align-items:center;
  width: 100%;
  height: 25px;
  background-color: ${props => (props.status === 'Cancelled' || 
                      props.status === 'Cancelled_By_Customer' ||
                      props.status === 'Cancelled_By_Restaurant' ||
                      props.status === 'Did_Not_Attend' ? '#e8733c'
                      : props.status === 'Cancelled_By_Expired_Code' ? '#dcb1e0'
                      : props.status === 'Pending_from_customer_Warning' ? '#d6a7c1'
                      : props.status === 'Edited' ? '#7e538a'
                      : props.status === 'Pending_from_restaurant' ? '#cacf44'
                      : props.status === 'Pending_from_customer' ? '#959657'
                      : props.status === 'Late' ? '#ebb052'
                      : '#58c49f')};
  border:0;
`;

const ReservationBot = styled.div`
  display: flex;
  flex-wrap: wrap;
  
  width:100%;
  height:100%;
  overflow:auto;

  /* This styles the scrollbar track (the background where the thumb moves) */
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent; /* or use a light shade of gray */
  }

  /* This styles the scrollbar thumb (the draggable element) */
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #cccccc; /* choose a color that suits your design */
    &:hover {
      background-color: #bbbbbb; /* slightly darker shade on hover */
    }
  }

  /* This styles the scrollbar itself */
  &::-webkit-scrollbar {
    width: 5px;
    background-color: transparent; /* makes the background of the scrollbar transparent */
  }
`;



const ReservationBotItems = styled.div`
  display: flex;
  flex-wrap:wrap;
  margin:5px;
`;

const ReservationBoxWrap = styled.div `
  display: flex;
  flex-wrap:wrap;
  padding:5px;
  margin:0;
  width:100%;
`;
const ReservationBotItemsContainer = styled.div`
  display: flex;
  width:100%;
`;

const ReservationFlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right:5px;
`;

const ReservationTitle = styled.h2`
  font-size: 1rem;
  color: ${props => (props.status === 'Cancelled' ? 'white' 
  : props.status === 'Pending_from_restaurant' ? 'black' : props.status === 'Edited'? 'white' : 'white')};
  margin-top:4px;
  margin-left:5px;
`;



const DateSeparator  = styled.div`
  font-size:19px;
  display:flex;
  justify-content:center;
  padding:5px;
  margin:0;
  background-color:#88d1af;
  color: #ffffff;
  border-radius:10px;
  border: 1px solid #e8e6e6;
  width:100%;
  height:38px;
  margin-bottom:6px;
  margin-top:20px;
`;


function NotificationBell({restaurant_id, allReservations}) {

  const reservationAccept = useSelector(state => state.reservationAccept)
  const { messageAccept, isLoadingAccept } = reservationAccept

  const reservationCancel = useSelector(state => state.cancelReservation)
  const { cancelMessage, isLoadingCancel } = reservationCancel

  const modalRef = useRef(null);
  const modalPhoneRef = useRef(null);
  const modalCancelRef = useRef(null);
  const notificationBellRef = useRef(null);
  const editModalRef = useRef(null);
  const statusBoxRef = useRef(null);
  const colRef = useRef(null); // Step 1: Create a reference

  const dispatch = useDispatch();
  const [clicked, setClicked] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [isReservationModalOpen, setIsReservationModalOpen] = useState(false);
  const [isCreatingReservation, setIsCreatingReservation] = useState(false);
  const [tokens, setTokens] = useState('');
  const [reservationForChat, setReservationForChat] = useState('');
  const [chatModalTrueOrFalse, setChatModalTrueOrFalse] = useState(false);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [showPhoneModal, setShowPhoneModal] = useState(false);
  const [completePhone, setCompletePhone] = useState('');
  
  const [pendingNumber, setPendingNumber] = useState(0);
  const [cancelledNumber, setCancelledNumber] = useState(0);

  const [allReservationsWithDateSeparators, setAllReservationsWithDateSeparators] = useState([]);
  const [reset, setReset] = useState(false);

  useEffect(() => {
    dispatch(fetchAllReservations(restaurant_id, null, null, null, 'all_pending_reservations_from_all_time'));
  },[dispatch, restaurant_id])

  useEffect(() => {
    if (allReservations) {
      // Calculate the cancelled number
      const cancelledCount = allReservations.filter(reservation => reservation.cancelled_warning === true).length;
      // Calculate the pending number
      const pendingCount = allReservations.filter(reservation => reservation.status === 'Cancelled_By_Customer_Warning' ||
                                                                reservation.status === 'Pending_from_restaurant' ||
                                                                reservation.status === 'new_chat_message' ||
                                                                reservation.chat_notification_restaurant_all).length;
      // Set the state
      setCancelledNumber(cancelledCount);
      setPendingNumber(pendingCount);
      console.log('allReservations inside useffect: ', allReservations)
      console.log(pendingCount)
    }
    
  },[allReservations]);

  useEffect(() => {
    if (allReservations && allReservations.length > 0) {
      // Make sure your dates are sorted, if they're not already.
      // This sort function assumes your dates are in 'YYYY-MM-DD' format and are strings.
      const sortedReservations = [...allReservations].sort((a, b) => a.date.localeCompare(b.date));
  
      const reservationsWithSeparators = [];
      let lastDate = null;
  
      sortedReservations.forEach((reservation) => {
        // If this reservation is on a different day, add a date separator.
        if (reservation.date !== lastDate) {
          reservationsWithSeparators.push({
            isDateSeparator: true,
            date: reservation.date,
          });
          lastDate = reservation.date;
        }
        // Add the regular reservation.
        reservationsWithSeparators.push(reservation);
      });
  
      setAllReservationsWithDateSeparators(reservationsWithSeparators);
    }
  }, [allReservations]);

  // Define the start and end points for the animation
  const animationDegreesYellow = clicked
  ? { start: 180, end: 0 } // Clicked: Go to the other side
  : { start: 0, end: 180 }; // Unclicked: Return to the original position
  const animationDegreesRed = clicked
  ? { start: 140, end: -40 } // Clicked: Go to the other side
  : { start: -40, end: 140 }; // Unclicked: Return to the original position
  const counterRotate = clicked ? 'rotate(0deg)' : 'rotate(-180deg)';
  const counterRotateRed = clicked ? 'rotate(40deg)' : 'rotate(-140deg)';
 
    // New function to handle click
    const handleClick = () => {
      setAnimate(true);
      if (!clicked) {
        setClicked(true);
        
        
      } else {
        setClicked(false);
      }
      // Timeout duration should match your animation duration
      setTimeout(() => {
        setAnimate(false);
      }, 200); // this should match the duration of your tremble animation
    };

    const handleOpenEditReservationModal = (tokeni) => {
      setIsReservationModalOpen(true);
      setIsCreatingReservation(false);
      setTokens(tokeni);
    };

    const handleShowAcceptModal = (value) => {
      setSelectedReservation(value);
      setShowAcceptModal(true);
    };

  const handleShowModal = (value) => {
    setSelectedReservation(value);
    setTokens(value);
    setShowModal(true);
  };

  const handleCheckCancel = (value_token) => {
    dispatch(checkCancelAction(value_token));
  };

  const handleCloseAcceptModal = () => {
    setShowAcceptModal(false);
  };

  const handleCloseReservationModal = () => {
    setIsReservationModalOpen(false);
  };


  const handleAccept = () => {
    dispatch(postReservationAccept(selectedReservation, false, 
      restaurant_id, 'null', 'null', 'null', 
      'null'));
    setShowAcceptModal(false);
  };

  const handleCancel = () => {
    dispatch(fetchCancelReservation(tokens, false, 
      restaurant_id, 'null', 'null', 'null', 
      'null'));
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleClickOutside = (event) => {
    // Check if the clicked target is the modal, notification bell, or their descendants
    if (
      (notificationBellRef.current && notificationBellRef.current.contains(event.target)) ||
      (editModalRef.current && editModalRef.current.contains(event.target)) ||
      (modalCancelRef.current && modalCancelRef.current.contains(event.target)) ||
      (modalPhoneRef.current && modalPhoneRef.current.contains(event.target)) ||
      (statusBoxRef.current && statusBoxRef.current.contains(event.target)) ||
      (modalRef.current && modalRef.current.contains(event.target)) 
    ) {
      // Clicked inside the modal or on the notification bell, ignore the click
      return;
    }
    if (colRef.current && !colRef.current.contains(event.target)) {
      setClicked(false);
    }
  };

  useEffect(() => {
    // Step 3: Add event listener when component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []); 

  const handleSetPhoneNumber = (country_code, phone_number) => {
    const countryCode = country_code;
    const phoneNumber = phone_number;
    const countryCodeLength = countryCode.length;
    const formattedPhoneNumber = phoneNumber.startsWith(countryCode) 
      ? countryCode + ' ' + phoneNumber.slice(countryCodeLength) 
      : phoneNumber;
    setCompletePhone(formattedPhoneNumber);
    setShowPhoneModal(!showPhoneModal)
};

const updateSearchResultsTableNumber = (id, newTableNumber, status) => {
  setAllReservationsWithDateSeparators(prevResults => {
    // Find the item with the matching ID
    const foundItem = prevResults.find(item => item.id === id);

    if (foundItem) {
      // Create a new array with the updated object
      return prevResults.map(item =>
        item.id === id ? { ...item, table_number: newTableNumber, status: status } : item
      );
      
    } else {
      // Handle the case where the item with the given ID is not found
      console.error("Invalid ID. Unable to update search results.");
      return prevResults;
    }
  });
};

const openReservationChat = (reservation) => {
  setReservationForChat(reservation);
  setChatModalTrueOrFalse(true);
};

  return (
    <>
    <CircleContainer ref={notificationBellRef} onClick={handleClick}>
        <AnimatedIcon animate={animate}  icon={faBell} style={{color: "#5fddb3", fontSize:'60px'}} />
      {pendingNumber > 0 &&
        <YellowCircle  animate={true} animationDegrees={animationDegreesYellow}>
          <div style={{ transform: counterRotate }}>{pendingNumber < 10 ? pendingNumber : '9+'}</div> {/* Wrapping the number to keep it straight */}
        </YellowCircle>
      }
      {cancelledNumber > 0 &&
        <RedCircle  animate={true} animationDegrees={animationDegreesRed}>
          <div style={{ transform: counterRotateRed }}>{cancelledNumber < 10 ? cancelledNumber : '9+'}</div> {/* Wrapping the number to keep it straight */}
        </RedCircle>
      }
      
    </CircleContainer>
    <BarContainer clicked={clicked}>
    
      <Row style={{height:'100%', display:'flex', justifyContent:'end'}}>
        <Col ref={colRef} xs={12} sm={12} md={6} xl={4} style={{color:'black', height:'99%'}}>
          <InsideContainerBox>
          <div className="menu-left"></div>
          <InsideContainer>
            <div style={{fontSize:'20px'}}>Notificações</div>
          { allReservations && allReservations.length > 0 ? (
          allReservationsWithDateSeparators.map((reservation, index) => {
            let filter_reservation = true;
            if (reservation.status === 'Pending_from_restaurant' ||
                reservation.status === 'Edited' ||
                reservation.status === 'Waiting_List' ||
                reservation.status === 'cancel_warning' ||
                reservation.status === 'Cancelled_By_Expired_Code' ||
                reservation.chat_notification_restaurant_all === true ||
                reservation.cancelled_warning === true ) {
                  filter_reservation = true;
                } else {
                  filter_reservation = false;
                }

              if (!filter_reservation) {
                return (
                  <></>
                );
              }
              
              if (reservation.isDateSeparator) {
              return (
                <DateSeparator key={index}>
                  {reservation.date}
                </DateSeparator>
              );
            } else {
          return (
            <ReservationCard key={reservation.id}>
              <ReservationTopBot>

              <ReservationTop status={reservation.status}>
                <Row style={{marginBottom:'0px', width:'100%', maxHeight:'100%'}}>
                  <Col xs={6} style={{overflow: 'hidden', whiteSpace: 'nowrap'}}>
                    <ReservationTitle status={reservation.status}>
                      {reservation.full_name}
                    </ReservationTitle>
                  </Col>
                  <Col xs={6} style={{overflow: 'hidden', whiteSpace: 'nowrap'}}>
                    <ReservationTitle status={reservation.status}>
                    {reservation.is_phone_confirmed ?
                      <a style={{ color:'white', cursor: "pointer" }} 
                        onClick={() => handleSetPhoneNumber(reservation.country_code, reservation.phone_number)}
                      >
                              <FontAwesomeIcon size='lg' icon={faPhone}  />
                      </a>
                      :
                      <a style={{ color:'white', cursor: "pointer" }} 
                      onClick={() => handleSetPhoneNumber(reservation.country_code, reservation.phone_number)}
                    >
                            <FontAwesomeIcon size='lg' icon={faPhoneSlash}  /> Não confirmado
                    </a>
                      
                    }
                    </ReservationTitle>
                  </Col>
                </Row>
                
                
              </ReservationTop>
              
                <Row>
                <Col xs={12} md={12}>
                <ReservationBot>
                <ReservationBotItems style={{color:'#008059', flexWrap: "wrap"}}>
                  {reservation.status === 'Pending_from_customer' ? 'Reserva em curso'
                  : reservation.status === 'Pending_from_restaurant' ? 'Pendente de aceitação'
                  : reservation.status === 'Cancelled_By_Expired_Code' ? 'Código expirado'
                  : reservation.status === 'Edited' ? 'Alteração'
                  : ''}
                </ReservationBotItems>
                {reservation.status === 'Edited' &&
                <ReservationBotItemsContainer style={{marginLeft:'10px'}}>
                  <CompareChanges before={reservation.history.find(item => item.history_id === reservation.restore_version_id)} after={reservation} />
                </ReservationBotItemsContainer>
                }
                {reservation.message != '' &&
                  <ReservationBotItems style={{flexWrap: "wrap"}}>
                    <div style={{color:'#f5bf42'}}>
                      <FontAwesomeIcon icon={faEnvelope} size='lg' style={{color: "#f5bf42", marginRight: '5px', paddingTop: '3px'}} />
                      {reservation.message}
                    </div>
                  </ReservationBotItems>
                }
                <ReservationBotItems>
                  <FontAwesomeIcon icon={faCalendar} size='lg' style={{color: "#5ace8c", marginRight: '5px', paddingTop: '3px'}} />
                  {reservation.date}
                </ReservationBotItems>
                <ReservationBotItems>
                  <FontAwesomeIcon icon={faSun} size='lg' style={{color: "#5ace8c", marginRight: '5px', paddingTop: '3px'}} />
                  { reservation.shift === 'Breakfast' ? 'Pequeno Almoço' : reservation.shift === 'Lunch' ? 'Almoço' : reservation.shift === 'Dinner' ? 'Jantar' : 'Todos'}
                </ReservationBotItems>
                <ReservationBotItems>
                  <FontAwesomeIcon icon={faStopwatch} size='lg' style={{color: "#5ace8c", marginRight: '5px', paddingTop: '3px'}} />
                  {reservation.time}
                </ReservationBotItems>
                <ReservationBotItems>
                  <FontAwesomeIcon icon={faUsers} size='lg' style={{color: "#5ace8c", marginRight: '5px', paddingTop: '3px'}} />
                  {reservation.number_of_people}
                </ReservationBotItems>
                {reservation.table_place_preference != null && 
                  <ReservationBotItems>
                    <FontAwesomeIcon icon={faMap} size='lg' style={{color: "#5ace8c", marginRight: '5px', paddingTop: '3px'}} />
                      {reservation.table_place_preference.place_of_table}
                  </ReservationBotItems>
                }
                {reservation.baby_chair != 0 && 
                  <ReservationBotItems>
                    <FontAwesomeIcon icon={faBaby} size='lg' style={{color: "#5ace8c", marginRight: '5px', paddingTop: '3px'}} />
                      {reservation.baby_chair}
                  </ReservationBotItems>
                }
                {reservation.accessability != 'not_needed' && 
                  <ReservationBotItems>
                    <FontAwesomeIcon icon={faWheelchairMove} size='lg' style={{color: "#5ace8c", marginRight: '5px', paddingTop: '3px'}} />
                      {reservation.accessability === 'wheel_chair' ? 'Cadeira de Rodas' : 'Moletas' }
                  </ReservationBotItems>
                }
                  <ReservationBotItems onClick={() => openReservationChat(reservation)}>
                    <ReservationChat reservation={reservation} isClient={false} />
                  </ReservationBotItems>
                  <ReservationBotItemsContainer>
                  <ReservationBoxWrap>
                </ReservationBoxWrap>
                  </ReservationBotItemsContainer>
                  </ReservationBot>
                  </Col>
                    <Col xs={12} md={12} style={{height:'120px'}}>
                    
                    </Col>
                </Row>
               
              
              </ReservationTopBot>
              {/* 
              <ReservationRight>
                  <EditButton variant="secondary" onClick={() => handleOpenEditReservationModal(reservation.token)} >
                      <FontAwesomeIcon icon={faFilePen} style={{color: "#ffffff",}} />
                  </EditButton>
                  {(reservation.status === 'Pending_from_restaurant'  ||  
                    reservation.status === 'Pending_from_customer' ||
                    reservation.status === 'Edited' ) &&
                    <SuccessButton variant="success" onClick={() => handleShowAcceptModal(reservation.token)}>
                      <StyledIcon size='xl' icon={faCircleCheck} style={{color: "#06bf00"}} />
                    </SuccessButton>
                  }
                  {(reservation.status === 'Pending_from_restaurant'  ||  
                    reservation.status === 'Pending_from_customer' ||
                    reservation.status === 'Edited' ) &&
                    <DangerButton variant="danger" onClick={() => handleShowModal(reservation.token)}>
                      <StyledIcon  size='xl' icon={faCircleXmark} style={{color: "#d94009"}} />
                    </DangerButton>
                  }
                  {(reservation.cancelled_warning === true ?  
                      <SuccessButton variant="success" onClick={() => handleCheckCancel(reservation.token)} style={{fontSize:'17px', width:'100%', height:'100%'}}>
                        <StyledIcon size='xl' icon={faEye} style={{color: "#06bf00"}} />
                      </SuccessButton>
                    : reservation.status === 'Cancelled_By_Expired_Code' ?
                      <SuccessButton variant="success" onClick={() => handleCheckCancel(reservation.token)} style={{fontSize:'17px', width:'100%', height:'100%'}}>
                        <StyledIcon size='xl' icon={faEye} style={{color: "#06bf00"}} />
                      </SuccessButton>
                    :
                    <>
                    </>
                    )
                  }
                </ReservationRight>
              */}
              <StyledIcon 
                    onClick={() => handleOpenEditReservationModal(reservation.token)} 
                    icon={faGear} style={{color: "#ffffff",position:'absolute', right:'10px',top:'3px', fontSize:'20px', cursor:'pointer'}} 
                  />
                  {(!cancelledStatuses.includes(reservation.status) || reservation.cancelled_warning ) &&
                    <ReservationStatusBox
                    reservation={reservation}
                    updateSearchResultsTableNumber={updateSearchResultsTableNumber}
                    index={reservation.id}
                    statusBoxRef={statusBoxRef}
                  />
                  }
              
          </ReservationCard>
          )}})
        ) : allReservations && allReservations.length === 0 ? (
          <p>Sem novas reservas.</p>
        ) : (
          <p>Nothing to be found</p>
        )}

          </InsideContainer>

          </InsideContainerBox>
          
        </Col>
        
      </Row>
    </BarContainer>
    <Modal show={showAcceptModal} onHide={handleCloseAcceptModal}>
    <div ref={modalRef}>
            <Modal.Header closeButton>
              <Modal.Title>Aceitar Reserva</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
            {isLoadingAccept?
              <Loader></Loader>:
              <Button variant="success" onClick={() => handleAccept()}>
                Sim
              </Button>
              }
              <Button variant="secondary" onClick={handleCloseAcceptModal}>
                Não
              </Button>
              
              
            </Modal.Footer>
            </div>
          </Modal>
          
          <Modal show={showModal} onHide={handleCloseModal}>
            <div ref={modalCancelRef}>
            <Modal.Header closeButton>
              <Modal.Title>Rejeitar reserva</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              {isLoadingCancel?
                <Loader></Loader>:
                <Button variant="success" onClick={handleCancel}>
                  Sim
                </Button>
              }
                <Button variant="danger" onClick={handleCloseModal}>
                  Não
                </Button>
            </Modal.Footer>
            </div>
            
          </Modal>
          <ReservationScreen
            editModalRef={editModalRef}
            from_restaurant={true}
            creating={isCreatingReservation}
            setCreating={(value) => setIsCreatingReservation(value)}
            isEditModalOpen={isReservationModalOpen}
            setIsEditModalOpen={setIsReservationModalOpen}
            restaurantId={restaurant_id}
            handleCloseReservationModal={handleCloseReservationModal}
            token_from_restaurant={tokens}
            reset={reset}
          />
          <Modal show={showPhoneModal} onHide={() => setShowPhoneModal(!showPhoneModal)}>
            <div ref={modalPhoneRef}>
            <Modal.Header>
              <div style={{fontSize:'20px'}}>
              +{completePhone}
              </div>
              
            </Modal.Header>
            <Modal.Footer>
            <a href={`tel:${'+' + completePhone}`} style={{cursor: "pointer" }}>
              <Button style={{backgroundColor:'#3bad59',}}> 
                        Ligar 
                  <FontAwesomeIcon size='lg' icon={faPhone}  style={{marginLeft:'7px'}}/>
              </Button>
            </a>
            
            <Button style={{backgroundColor:'red',}} onClick={() => setShowPhoneModal(!showPhoneModal)}> 
                        Fechar
                  <FontAwesomeIcon size='lg' icon={faXmark}  style={{marginLeft:'7px'}}/>
              </Button>
            </Modal.Footer>
            </div>
            
        </Modal>
        <ReservationChat reservation={reservationForChat} 
                        isClient={false} 
                        hideButton={true} 
                        openModal={chatModalTrueOrFalse} 
                        setChatModalTrueOrFalse={setChatModalTrueOrFalse}/>
    </>
    
  );
}

export default NotificationBell;
