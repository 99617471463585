import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import "react-datepicker/dist/react-datepicker.css";
import { pt } from 'date-fns/locale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRectangleXmark, faFloppyDisk,   } from '@fortawesome/free-solid-svg-icons';
import CustomSelectArray from './CustomSelectArray'
import { Row, Col, Card } from 'react-bootstrap';

const StyledDatePickerWrapper = styled.div`
  width: 100%;
  font-size: 1.5rem;
  justify-content: center;

  .react-datepicker {
    font-size: 16px;
    
  }

  .react-datepicker__day:hover {
    background-color: inherit !important;
    opacity: 1 !important;
    transform: none !important;
    box-shadow: none !important;
  }
  

  .react-datepicker__day--selected:hover {
    background-color: #61ab9b !important;  /* Setting hover background color for the selected date */
  }



  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    border-color: white;
    color:white;
  }


  .react-datepicker__header {
    background-color: #f7fffc; 
    color: white; /* Set the text color */
    border-color: white;
  }

  .react-datepicker__current-month {
    font-size: 20px;
  }

  .react-datepicker__day {
    width: 40px;
    line-height: 40px;
    border: 1px solid gray;
    border-radius: 4px;
  }

  .react-datepicker__day--selected {
    background-color: #25d9b2 !important; 
  }

  .date-sums {
    display:flex;
    width: 100%;
    height:100%;
    max-height:2.5rem;
    justify-content:center;
    position:relative;
  }
  
  .day {
  }

  .sum-left {
    position: absolute;
    background-color: yellow;
    border-radius: 50%;
    padding: 2px;
    font-size: 12px;
    color: black;
    left: 0;
    bottom: -3px;
    transform: translateX(-26%);
    height: 16px; /* Adjust the height as needed */
    line-height: 16px; /* Adjust the line-height to vertically center the text */
  }
  
  .sum-right {
    position: absolute;
    background-color: #71bfa8;
    border-radius: 50%;
    padding: 2px;
    font-size: 12px;
    color: white;
    right: 0;
    bottom: -3px;
    transform: translateX(26%);
    height: 16px; /* Adjust the height as needed */
    line-height: 16px; /* Adjust the line-height to vertically center the text */
  }
  .text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`;


const Cube = styled.div`
  width: 50px;
  height: 50px;
  position:relative;
  border-radius:5px;
  display:flex;
  justify-content:center;
  align-items:center;
  padding-right:10px;
  padding-bottom:15px;
`;

const Container = styled.div`
  width: 350px;
  height: auto;
  background-color:white;
  position:relative;
  border-radius:5px;
  padding:5px;
`;

function CustomDatePicker({ handleTimeChange, date, handleCloseDateModal }) {
  const today = new Date();
  const formattedToday = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
  const reservationSumss = useSelector((state) => state.reservationSums);
  const { reservationSums, isLoadingReservationSums, errorReservationSums } = reservationSumss;
  const [selectedDate, setSelectedDate] = useState(today);
  const [highlightedDates, setHighlightedDates] = useState({});
  const [closeModal, setCloseModal] = useState(false);
  const [updateMachine, setUpdateMachine] = useState(false);
  const [shiftWithAll, setShiftWithAll] = useState([]);
  const [selectedShifts, setSelectedShifts] = useState([]);

  console.log('data inside date picker ', date);

  const handleSelectChangeShift = (newSelectedItems) => {
    setSelectedShifts(newSelectedItems);
  };

  useEffect(() => {
    if (reservationSums) {
      const filterNames = reservationSums.filter_names || [];
      setShiftWithAll(filterNames);
      setSelectedShifts(filterNames); // Default to selecting all available shifts
    }
  }, [reservationSums]);

  useEffect(() => {
    const datesWithSums = {};

    if (reservationSums && reservationSums.date_summary) {
      reservationSums.date_summary.forEach((dateSummary) => {
        const { date, ...shiftSums } = dateSummary;

        // Initialize an object to hold sums and warnings for the selected shifts
        const shiftsData = {
          sum1: 0, // Total of accepted reservations
          sum2: 0, // Total of pending or edited reservations
          warning: false,
        };

        selectedShifts.forEach((shift) => {
          // Check if the shift data exists in the current date summary
          if (shiftSums[shift]) {
            // Add the sums of accepted and pending/edited reservations for this shift
            shiftsData.sum1 += parseInt(shiftSums[shift].accepted, 10);
            shiftsData.sum2 += parseInt(shiftSums[shift].pending_or_edited, 10);

            // Update the warning flag if there's a warning for this shift
            if (shiftSums[shift].warnings > 0) {
              shiftsData.warning = true;
            }
          }
        });

        // Assign the computed data to the corresponding date
        datesWithSums[date] = shiftsData;
      });

      setHighlightedDates(datesWithSums);
    }
  }, [reservationSums, selectedShifts]);

  useEffect(() => {
    const formattedDate = `${selectedDate.getFullYear()}-${String(selectedDate.getMonth() + 1).padStart(2, '0')}-${String(selectedDate.getDate()).padStart(2, '0')}`;
    handleTimeChange(formattedDate);
    if (closeModal) {
      handleCloseDateModal();
      setCloseModal(false);
    }
  }, [selectedDate, updateMachine, closeModal]);

  useEffect(() => {
    if (date !== 'null') {
      let parts = date.split('-');
      let dateObject = new Date(parts[0], parts[1] - 1, parts[2]);
      setSelectedDate(dateObject);
      handleTimeChange(date);
    }
  }, []);

  const renderDayContents = (day, date) => {
    const dateStr = date.toLocaleDateString('en-GB');

    if (highlightedDates[dateStr]) {
      const { sum1, sum2, warning } = highlightedDates[dateStr];

      return (
        <div className="date-sums">
          {warning === true && <div className="warning-indicator"></div>}
          <div className="day">{day}</div>
          <div className="footer">
            {sum2 !== 0 && (
              <div className="sum-left">
                <div className="text">{sum2}</div>
              </div>
            )}
            {sum1 !== 0 && (
              <div className="sum-right">
                <div className="text">{sum1}</div>
              </div>
            )}
          </div>
        </div>
      );
    }

    return day;
  };

  return (
    <StyledDatePickerWrapper>
      <Container>
        <Row>
          <Col xs={10} style={{ paddingTop: '5px' }}>
            <CustomSelectArray
              items={shiftWithAll}
              onSelectChange={handleSelectChangeShift}
              selectedItems={selectedShifts}
            />
          </Col>
          <Col xs={2}>
            <Cube>
              <FontAwesomeIcon
                onClick={() => setCloseModal(true)}
                size="xl"
                icon={faRectangleXmark}
                style={{
                  '--fa-primary-color': '#eb6434',
                  color: '#eb6434',
                  cursor: 'pointer',
                }}
              />
            </Cube>
          </Col>
        </Row>

        {reservationSums ? (
          <DatePicker
            selected={selectedDate}
            onChange={(date) => {
              setSelectedDate(date);
              setCloseModal(true);
              setUpdateMachine(!updateMachine);
            }}
            dayClassName={(date) => {
              let classNames = '';

              if (
                selectedDate &&
                date.getDate() !== selectedDate.getDate() &&
                date.getMonth() !== selectedDate.getMonth() &&
                date.getFullYear() !== selectedDate.getFullYear()
              ) {
                // Class name for the selected date
                classNames += 'react-datepicker__day';
              }

              return classNames.trim();
            }}
            inline
            renderDayContents={renderDayContents}
            locale={pt}
            weekStartsOn={1} 
          />
        ) : (
          <></>
        )}
      </Container>
    </StyledDatePickerWrapper>
  );
}

export default CustomDatePicker;