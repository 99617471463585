import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getRestaurantInfoClient } from '../actions/userActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faCalendar, faClock, faUser, faFilePen } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { Modal,  Form, Row, Col  } from 'react-bootstrap';
import ReservationScreen from '../screens/ReservationScreen';

const ModalBackdrop = styled.div`
  position: fixed;
  z-index: 109;
  padding:10px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 450px;
  width:450px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position:relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

const UserInfoSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
`;

const IconContainer = styled.div`
  margin-bottom: 10px;
`;

const Name = styled.h3`
  margin: 0;
`;

const PhoneNumber = styled.p`
  margin: 0;
`;

const ReservationsList = styled.div`
  height: 300px;
  overflow-y: auto;
  margin-top:20px;

  /* Modern Scrollbar Style */
  &::-webkit-scrollbar {
    width: 10px; /* Adjust width for vertical scrollbar */
    height: 10px; /* Adjust height for horizontal scrollbar */
  }

  &::-webkit-scrollbar-track {
    background-color: #f4f4f4; /* Color of the track of scrollbar */
    border-radius: 10px; /* Adjust border-radius for the track */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bdbdbd; /* Color of the thumb of scrollbar */
    border-radius: 10px; /* Adjust border-radius for the thumb */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a8a8a8; /* Color of the thumb of scrollbar when hovered */
  }

  /* For Internet Explorer */
  -ms-overflow-style: auto;
`;

const ReservationItem = styled.div`
  border-bottom: 1px solid #ccc;
  height:50px;
  padding-top:6px;
  padding-bot:6px;
  &:last-child {
    border-bottom: none;
  }
`;

function ShowCustomerInfo({ phone_number, restaurant_id, isModalActive, setIsModalActive }) {
    const dispatch = useDispatch();

    const [isReservationModalOpen, setIsReservationModalOpen] = useState(false);
    const [isCreatingReservation, setIsCreatingReservation] = useState(false);
    const [tokens, setTokens] = useState('');
    const [reset, setReset] = useState(false);

    const clientInfo = useSelector(state => state.restaurant_clients);
    const { restaurantInfoClients, isLoadingGetRestaurantInfoClient, errorGetRestaurantInfoClient } = clientInfo;

    useEffect(() => {
        if (phone_number && restaurant_id) {
            dispatch(getRestaurantInfoClient(phone_number, restaurant_id));
        }
    }, [dispatch, phone_number, restaurant_id]);

    const closeModal = () => setIsModalActive(false);

    const handleCloseReservationModal = () => {
        setIsReservationModalOpen(false);
    };

    const handleOpenEditReservationModal = (tokeni) => {
        setIsReservationModalOpen(true);
        setIsCreatingReservation(false);
        setTokens(tokeni);
    };

    return (
        <>
            {isModalActive && (
                <ModalBackdrop onClick={closeModal}>
                    <ModalContent onClick={(e) => e.stopPropagation()}>
                        <CloseButton onClick={closeModal}>&times;</CloseButton>
                        <UserInfoSection>
                            <IconContainer>
                                <FontAwesomeIcon icon={faUserCircle} size="4x" />
                            </IconContainer>
                            {isLoadingGetRestaurantInfoClient && <p>Loading...</p>}
                            {restaurantInfoClients && (
                                <>
                                    <Name>{restaurantInfoClients.clientInfo.client.name}</Name>
                                    <PhoneNumber>{'+' + restaurantInfoClients.clientInfo.client.phone_number}</PhoneNumber>
                                </>
                            )}
                        </UserInfoSection>
                        <h4 style={{textAlign:'center',padding:'10px'}}>Histórico de reservas</h4>
                        <Row style={{width:'100%', display:'flex', flexWrap:'nowrap', padding:'0', margin:'0'}}>
                            <Col xs={5}  style={{display:'flex',flexWrap:'wrap', justifyContent:'start'}}>
                            <FontAwesomeIcon size='lg' icon={faCalendar} style={{color:'#5cb590'}}/>
                            </Col>
                            <Col xs={2}  style={{display:'flex', flexWrap:'wrap', justifyContent:'start'}}>
                            <FontAwesomeIcon size='lg' icon={faClock} style={{color:'#5cb590'}}/>
                            </Col>
                            <Col xs={2}  style={{display:'flex',flexWrap:'wrap', justifyContent:'start'}}>
                            <FontAwesomeIcon size='lg' icon={faUser} style={{color:'#5cb590'}}/>
                            </Col>
                        </Row>
                        <ReservationsList>
                            
                            {restaurantInfoClients && restaurantInfoClients.reservations.map((reservation, index) => (
                                <ReservationItem key={index}>
                                    <Row style={{width:'100%', display:'flex', flexWrap:'nowrap', padding:'0', margin:'0'}}>
                                        <Col xs={5}  style={{display:'flex',flexWrap:'wrap', justifyContent:'start'}}>
                                            {reservation.date}
                                        </Col>
                                        <Col xs={2}  style={{display:'flex', flexWrap:'wrap', justifyContent:'start'}}>
                                            {reservation.time}
                                        </Col>
                                        <Col xs={2}  style={{display:'flex',flexWrap:'wrap', justifyContent:'start', paddingLeft:'20px'}}>
                                            {reservation.number_of_people}
                                        </Col>
                                        <Col xs={3}  style={{display:'flex',flexWrap:'wrap', justifyContent:'start', paddingLeft:'20px'}}>
                                            <FontAwesomeIcon 
                                                size='lg' icon={faFilePen} 
                                                style={{color:'#5cb590', cursor:'pointer'}}
                                                onClick={() => handleOpenEditReservationModal(reservation.token)}
                                            />
                                        </Col>
                                    </Row>
                                </ReservationItem>
                            ))}
                        </ReservationsList>
                    </ModalContent>
                </ModalBackdrop>
            )}
        <Modal show={isReservationModalOpen} onHide={() => setIsReservationModalOpen(false)}>
          <ReservationScreen
              from_restaurant={true}
              creating={isCreatingReservation}
              setCreating={(value) => setIsCreatingReservation(value)}
              isEditModalOpen={isReservationModalOpen}
              setIsEditModalOpen={setIsReservationModalOpen}
              restaurantId={restaurant_id}
              handleCloseReservationModal={handleCloseReservationModal}
              token_from_restaurant={tokens}
              reset={reset}
          />
        </Modal>
        
        </>
    );
}

export default ShowCustomerInfo;